// import {
//   GET_TEAMS_REQUEST,
//   GET_TEAMS_SUCCESS,
//   GET_TEAMS_FAILURE,
//   GET_ODDS_REQUEST,
//   GET_ODDS_SUCCESS,
//   GET_ODDS_FAILURE,
//   GET_TEAM_REQUEST,
//   GET_TEAM_SUCCESS,
//   GET_TEAM_FAILURE,
//   GET_TEAM_STATS_REQUEST,
//   GET_TEAM_STATS_SUCCESS,
//   GET_TEAM_STATS_FAILURE,
//   GET_GAME_ODDS_REQUEST,
//   GET_GAME_ODDS_SUCCESS,
//   GET_GAME_ODDS_FAILURE,
//   GET_TEAM_PLAYERS_REQUEST,
//   GET_TEAM_PLAYERS_SUCCESS,
//   GET_TEAM_PLAYERS_FAILURE,
//   GET_PLAYER_REQUEST,
//   GET_PLAYER_SUCCESS,
//   GET_PLAYER_FAILURE,
//   GET_TEAM_SCHEDULE_REQUEST,
//   GET_TEAM_SCHEDULE_SUCCESS,
//   GET_TEAM_SCHEDULE_FAILURE,
//   GET_PROP_BETS_REQUEST,
//   GET_PROP_BETS_SUCCESS,
//   GET_PROP_BETS_FAILURE,
//   GET_LEAGUES_REQUEST,
//   GET_LEAGUES_SUCCESS,
//   GET_LEAGUES_FAILURE,
//   GET_LIVE_GAME_SCORES_REQUEST,
//   GET_LIVE_GAME_SCORES_SUCCESS,
//   GET_LIVE_GAME_SCORES_FAILURE,
//   GET_PLAYER_ODDS_REQUEST,
//   GET_PLAYER_ODDS_SUCCESS,
//   GET_PLAYER_ODDS_FAILURE,
//   GET_UPCOMING_MATCHUPS_REQUEST,
//   GET_UPCOMING_MATCHUPS_SUCCESS,
//   GET_UPCOMING_MATCHUPS_FAILURE,
//   GET_RANDOM_UPCOMING_MATCHUPS_REQUEST,
//   GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS,
//   GET_RANDOM_UPCOMING_MATCHUPS_FAILURE,
//   GET_SCHEDULE_REQUEST,
//   GET_SCHEDULE_SUCCESS,
//   GET_SCHEDULE_FAILURE,
//   SEARCH_SPORTS_REQUEST,
//   SEARCH_SPORTS_SUCCESS,
//   SEARCH_SPORTS_FAILURE,
//   GET_PROP_BETS_PLAYERS_REQUEST,
//   GET_PROP_BETS_PLAYERS_SUCCESS,
//   GET_PROP_BETS_PLAYERS_FAILURE,
//   RESET_PROP_BETS_STATE,
//   RESET_LIVE_GAME_SCORES_STATE,
// } from '../constants/SportsConstants.js';
// import { sportsService } from '../../constants/services/sports.js';



// export const getTeams = (sport, league) => async dispatch => {
//   dispatch({type: GET_TEAMS_REQUEST});
//   try {
//     const response = await sportsService.getTeams(sport, league)
//     dispatch({type: GET_TEAMS_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_TEAMS_FAILURE, error});
//   }
// };


// export const getTeam = teamId => async dispatch => {
//   dispatch({type: GET_TEAM_REQUEST});
//   try {
//     const response = await sportsService.getTeam(teamId)
//     dispatch({type: GET_TEAM_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_TEAM_FAILURE, error});
//   }
// };


// export const getOdds = (sport, league) => async dispatch => {
//   dispatch({type: GET_ODDS_REQUEST});
//   try {
//     const response = await sportsService.getOdds(sport, league)
//     dispatch({type: GET_ODDS_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_ODDS_FAILURE, error});
//   }
// };

// export const getGameOdds = gameId => async dispatch => {
//   dispatch({type: GET_GAME_ODDS_REQUEST});
//   try {
//     const response = await sportsService.getGameOdds(gameId)
//     dispatch({type: GET_GAME_ODDS_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_GAME_ODDS_FAILURE, error});
//   }
// };

// export const getTeamStats = (sport, league, team) => async dispatch => {
//   dispatch({type: GET_TEAM_STATS_REQUEST});
//   try {
//     const response = await sportsService.getTeamStats(sport, league, team)
//     dispatch({type: GET_TEAM_STATS_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_TEAM_STATS_FAILURE, error});
//   }
// };

// export const getTeamPlayers = (sport, league, teamId) => async dispatch => {
//   dispatch({type: GET_TEAM_PLAYERS_REQUEST});
//   try {
//     const response = await sportsService.getTeamPlayers(sport, league, teamId)
//     dispatch({type: GET_TEAM_PLAYERS_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_TEAM_PLAYERS_FAILURE, error});
//   }
// };

// export const getPlayer = (playerId, noOdds) => async dispatch => {
//   dispatch({type: GET_PLAYER_REQUEST});
//   try {
//     const userInfo = localStorage.getItem('userInfo')
//       ? JSON.parse(localStorage.getItem('userInfo'))
//       : {};

//     const userId = userInfo?.id;
//     const response = await sportsService.getPlayer(playerId, noOdds, userId)
//     dispatch({type: GET_PLAYER_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_PLAYER_FAILURE, error});
//   }
// };

// export const getPropBetsPlayers = (teamId, playerIds) => async dispatch => {
//   dispatch({type: GET_PROP_BETS_PLAYERS_REQUEST});
//   try {
//     const userInfo = localStorage.getItem('userInfo')
//       ? JSON.parse(localStorage.getItem('userInfo'))
//       : {};

//     const userId = userInfo?.id;
//     const response = await sportsService.getPropBetsPlayers(teamId, playerIds, userId)
//     dispatch({type: GET_PROP_BETS_PLAYERS_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_PROP_BETS_PLAYERS_FAILURE, error});
//   }
// };

// export const getTeamSchedule = (sport, league, team) => async dispatch => {
//   dispatch({type: GET_TEAM_SCHEDULE_REQUEST});
//   try {
//     const response = await sportsService.getTeamSchedule(sport, league, team)
//     dispatch({type: GET_TEAM_SCHEDULE_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_TEAM_SCHEDULE_FAILURE, error});
//   }
// };

// export const getSchedule = (sport, league) => async dispatch => {
//   dispatch({type: GET_SCHEDULE_REQUEST});
//   try {
//     const response = await sportsService.getSchedule(sport, league)
//     dispatch({type: GET_SCHEDULE_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_SCHEDULE_FAILURE, error});
//   }
// };

// export const getPropBets = (gameId) => async dispatch => {
//   dispatch({type: RESET_PROP_BETS_STATE});
//   dispatch({type: GET_PROP_BETS_REQUEST});
//   try {
//     const response = await sportsService.getPropBets(gameId)
//     dispatch({type: GET_PROP_BETS_SUCCESS, payload: response});
//     return response
//   } catch (error) {
//     dispatch({type: GET_PROP_BETS_FAILURE, error});
//   }
// };

// export const getLeagues = (sport) => async dispatch => {
//   dispatch({type: GET_LEAGUES_REQUEST});
//   try {
//     const response = await sportsService.getLeagues(sport)
//     dispatch({type: GET_LEAGUES_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_LEAGUES_FAILURE, error});
//   }
// };

// export const getLiveScores = (sport, league, game_id) => async dispatch => {
//   dispatch({type: RESET_LIVE_GAME_SCORES_STATE});
//   dispatch({type: GET_LIVE_GAME_SCORES_REQUEST});
//   try {
//     const response = await sportsService.getLiveScores(sport, league, game_id)
//     dispatch({type: GET_LIVE_GAME_SCORES_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_LIVE_GAME_SCORES_FAILURE, error});
//   }
// };

// export const getLiveOdds = (sport, league, game_id) => async dispatch => {
//   dispatch({type: GET_LIVE_GAME_SCORES_REQUEST});
//   try {
//     const response = await sportsService.getLiveOdds(sport, league, game_id)
//     dispatch({type: GET_LIVE_GAME_SCORES_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_LIVE_GAME_SCORES_FAILURE, error});
//   }
// };

// export const getPlayerOdds = (sport, league, playerId) => async dispatch => {
//   dispatch({type: GET_PLAYER_ODDS_REQUEST});
//   try {
//     const response = await sportsService.getPlayerOdds(sport, league, playerId);
//     dispatch({type: GET_PLAYER_ODDS_SUCCESS, payload: response});
//     return response;
//   } catch (error) {
//     dispatch({type: GET_PLAYER_ODDS_FAILURE, error});
//   }
// };

// export const getUpcomingMatchups = (sport, league) => async dispatch => {
//   dispatch({ type: GET_UPCOMING_MATCHUPS_REQUEST });
//   try {
//     const response = await sportsService.getUpcomingMatchups(sport, league);
//     console.log(`Dispatching upcoming matchups for league: ${league}`, response);
//     dispatch({
//       type: GET_UPCOMING_MATCHUPS_SUCCESS,
//       payload: response,
//       category: league,
//     });
//     return response;
//   } catch (error) {
//     console.error(`Error dispatching upcoming matchups for league: ${league}`, error);
//     dispatch({ type: GET_UPCOMING_MATCHUPS_FAILURE, error });
//   }
// };

// export const getRandomUpcomingMatchups = () => async dispatch => {
//   dispatch({type: GET_RANDOM_UPCOMING_MATCHUPS_REQUEST});
//   try {
//     const response = await sportsService.getRandomUpcomingMatchups();
//     dispatch({
//       type: GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS,
//       payload: response,
//     });
//     return response;
//   } catch (error) {
//     dispatch({type: GET_RANDOM_UPCOMING_MATCHUPS_FAILURE, error});
//   }
// };

// export const searchSports = topic => async dispatch => {
//   dispatch({type: SEARCH_SPORTS_REQUEST});
//   try {
//     const response = await sportsService.searchSports(topic);
//     dispatch({type: SEARCH_SPORTS_SUCCESS, payload: response});
//   } catch (error) {
//     dispatch({type: SEARCH_SPORTS_FAILURE, error});
//   }
// };




// trying to add getleagueodds
import {
  GET_TEAMS_REQUEST,
  GET_TEAMS_SUCCESS,
  GET_TEAMS_FAILURE,
  GET_ODDS_REQUEST,
  GET_ODDS_SUCCESS,
  GET_ODDS_FAILURE,
  GET_TEAM_REQUEST,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  GET_TEAM_STATS_REQUEST,
  GET_TEAM_STATS_SUCCESS,
  GET_TEAM_STATS_FAILURE,
  GET_GAME_ODDS_REQUEST,
  GET_GAME_ODDS_SUCCESS,
  GET_GAME_ODDS_FAILURE,
  GET_TEAM_PLAYERS_REQUEST,
  GET_TEAM_PLAYERS_SUCCESS,
  GET_TEAM_PLAYERS_FAILURE,
  GET_PLAYER_REQUEST,
  GET_PLAYER_SUCCESS,
  UPDATE_PLAYER_RESULT,
  GET_PLAYER_FAILURE,
  GET_TEAM_SCHEDULE_REQUEST,
  GET_TEAM_SCHEDULE_SUCCESS,
  GET_TEAM_SCHEDULE_FAILURE,
  GET_PROP_BETS_REQUEST,
  GET_PROP_BETS_SUCCESS,
  GET_PROP_BETS_UPDATE_AWAY_TEAM_PLAYER,
  GET_PROP_BETS_UPDATE_HOME_TEAM_PLAYER,
  GET_PROP_BETS_FAILURE,
  GET_LEAGUES_REQUEST,
  GET_LEAGUES_SUCCESS,
  GET_LEAGUES_FAILURE,
  GET_LIVE_GAME_SCORES_REQUEST,
  GET_LIVE_GAME_SCORES_SUCCESS,
  GET_LIVE_GAME_SCORES_FAILURE,
  GET_PLAYER_ODDS_REQUEST,
  GET_PLAYER_ODDS_SUCCESS,
  GET_PLAYER_ODDS_FAILURE,
  GET_UPCOMING_MATCHUPS_REQUEST,
  GET_UPCOMING_MATCHUPS_SUCCESS,
  GET_UPCOMING_MATCHUPS_FAILURE,
  GET_RANDOM_UPCOMING_MATCHUPS_REQUEST,
  GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS,
  GET_RANDOM_UPCOMING_MATCHUPS_FAILURE,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  SEARCH_SPORTS_REQUEST,
  SEARCH_SPORTS_SUCCESS,
  SEARCH_SPORTS_FAILURE,
  GET_PROP_BETS_PLAYERS_REQUEST,
  GET_PROP_BETS_PLAYERS_SUCCESS,
  GET_PROP_BETS_PLAYERS_FAILURE,
  RESET_PROP_BETS_STATE,
  RESET_LIVE_GAME_SCORES_STATE,
  GET_LEAGUE_ODDS_REQUEST,
  GET_LEAGUE_ODDS_SUCCESS,
  GET_LEAGUE_ODDS_FAILURE,
  GET_UFC_FIGHTER_DETAILS_REQUEST,
  GET_UFC_FIGHTER_DETAILS_SUCCESS,
  GET_UFC_FIGHTER_DETAILS_FAILURE,
} from '../constants/SportsConstants.js';
import {
  sportsService
} from '../../constants/services/sports.js';

export const getTeams = (sport, league) => async dispatch => {
  dispatch({
    type: GET_TEAMS_REQUEST
  });
  try {
    const response = await sportsService.getTeams(sport, league)
    dispatch({
      type: GET_TEAMS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_TEAMS_FAILURE,
      error
    });
  }
};

export const getTeam = teamId => async dispatch => {
  dispatch({
    type: GET_TEAM_REQUEST
  });
  try {
    const response = await sportsService.getTeam(teamId)
    dispatch({
      type: GET_TEAM_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_TEAM_FAILURE,
      error
    });
  }
};

export const getOdds = (sport, league) => async dispatch => {
  dispatch({
    type: GET_ODDS_REQUEST
  });
  try {
    const response = await sportsService.getOdds(sport, league)
    dispatch({
      type: GET_ODDS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_ODDS_FAILURE,
      error
    });
  }
};

export const getGameOdds = gameId => async dispatch => {
  dispatch({
    type: GET_GAME_ODDS_REQUEST
  });
  try {
    const response = await sportsService.getGameOdds(gameId);
    console.log('Fetched Game Odds:', response);
    dispatch({
      type: GET_GAME_ODDS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_GAME_ODDS_FAILURE,
      error
    });
  }
};

export const getTeamStats = (sport, league, team) => async dispatch => {
  dispatch({
    type: GET_TEAM_STATS_REQUEST
  });
  try {
    const response = await sportsService.getTeamStats(sport, league, team)
    dispatch({
      type: GET_TEAM_STATS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_TEAM_STATS_FAILURE,
      error
    });
  }
};

export const getTeamPlayers = (sport, league, teamId) => async dispatch => {
  dispatch({
    type: GET_TEAM_PLAYERS_REQUEST
  });
  try {
    const response = await sportsService.getTeamPlayers(sport, league, teamId)
    dispatch({
      type: GET_TEAM_PLAYERS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_TEAM_PLAYERS_FAILURE,
      error
    });
  }
};

export const getPlayer = (playerId, noOdds) => async dispatch => {
  dispatch({
    type: GET_PLAYER_REQUEST
  });
  try {
    const userInfo = localStorage.getItem('userInfo') ?
      JSON.parse(localStorage.getItem('userInfo')) :
      {};

    const userId = userInfo?.id;
    const response = await sportsService.getPlayer(playerId, noOdds, userId)
    dispatch({
      type: GET_PLAYER_SUCCESS,
      payload: response
    });
    const player = response.playerDetails;
    const teamDetails = response.teamDetails;
    const gameOddsResponse = await sportsService.getUpcomingPlayerGameOdds(player[0].sport.id, player[0].league.name, player[0].id, player[0].team?.name, teamDetails[0].id);

    dispatch({
      type: UPDATE_PLAYER_RESULT,
      payload: gameOddsResponse
    });

    return response;
  } catch (error) {
    dispatch({
      type: GET_PLAYER_FAILURE,
      error
    });
  }
};

export const getPlayerResultWithScore = (playerId) => async dispatch => {

  try {
    const response = await sportsService.getPlayerResultWithScore(playerId)
    dispatch({
      type: UPDATE_PLAYER_RESULT,
      payload: response
    });

  } catch (error) {
    dispatch({
      type: GET_PLAYER_FAILURE,
      error
    });
  }
};

export const getPropBetsPlayers = (teamId, playerIds, sport) => async dispatch => {
  dispatch({
    type: GET_PROP_BETS_PLAYERS_REQUEST
  });
  try {
    const userInfo = localStorage.getItem('userInfo') ?
      JSON.parse(localStorage.getItem('userInfo')) :
      {};

    const userId = userInfo ?.id;
    const response = await sportsService.getPropBetsPlayers(teamId, playerIds, userId, sport)
    dispatch({
      type: GET_PROP_BETS_PLAYERS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_PROP_BETS_PLAYERS_FAILURE,
      error
    });
  }
};

export const getTeamSchedule = (sport, league, team) => async dispatch => {
  dispatch({
    type: GET_TEAM_SCHEDULE_REQUEST
  });
  try {
    const response = await sportsService.getTeamSchedule(sport, league, team)
    dispatch({
      type: GET_TEAM_SCHEDULE_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_TEAM_SCHEDULE_FAILURE,
      error
    });
  }
};

export const getSchedule = (sport, league) => async dispatch => {
  dispatch({
    type: GET_SCHEDULE_REQUEST
  });
  try {
    const response = await sportsService.getSchedule(sport, league)
    dispatch({
      type: GET_SCHEDULE_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_SCHEDULE_FAILURE,
      error
    });
  }
};

export const getPropBets = (gameId, game) => async dispatch => {
  dispatch({
    type: RESET_PROP_BETS_STATE
  });
  dispatch({
    type: GET_PROP_BETS_REQUEST
  });

  try {
    let response = {};
    if (game) {
      response.gameScore = {
        away_team_display: game.away_team_display,
        home_team_display: game.home_team_display,
        id: game.id,
        game_id: gameId,
        is_live: game.is_live,
        league: game.league,
        sport: game.sport,
        start_date: game.start_date,
        status: game.status.toLowerCase(),
        season_type: game.season_type,
        season_week: game.season_week,
        season_year: game.season_year,
        away_record:game.away_record,
        home_record:game.home_record
      };
      response.gameOdds = game.odds;
      response.home_team_info = game.home_team_details;
      response.away_team_info = game.away_team_details;
    } else {
      response = await sportsService.getPropBetsGame(gameId);
    }
    dispatch({
      type: GET_PROP_BETS_SUCCESS,
      payload: response
    });
    return response
  } catch (error) {
    dispatch({
      type: GET_PROP_BETS_FAILURE,
      error
    });
  }
};

export const getPropBetsHomeTeamPlayers = (response) => async dispatch => {
  try {
    const game = response.gameScore;
    const homeTeamDetails = response.home_team_info;
    const homeTeamPlayer = await sportsService.getTeamPlayers(game.sport.id, game.league.id, homeTeamDetails.id);

    dispatch({
      type: GET_PROP_BETS_UPDATE_HOME_TEAM_PLAYER,
      payload: homeTeamPlayer
    });
    return homeTeamPlayer;
  } catch (error) {
    dispatch({
      type: GET_PROP_BETS_FAILURE,
      error
    });
  }
};


export const getPropBetsAwayTeamPlayers = (response) => async dispatch => {
  try {
    const game = response.gameScore;

    const awayTeamDetails = response.away_team_info;
    const awayTeamPlayer = await sportsService.getTeamPlayers(game.sport.id, game.league.id, awayTeamDetails.id);

    dispatch({
      type: GET_PROP_BETS_UPDATE_AWAY_TEAM_PLAYER,
      payload: awayTeamPlayer
    });
    return awayTeamPlayer;
  } catch (error) {
    dispatch({
      type: GET_PROP_BETS_FAILURE,
      error
    });
  }
};

export const getLeagues = (sport) => async dispatch => {
  dispatch({
    type: GET_LEAGUES_REQUEST
  });
  try {
    const response = await sportsService.getLeagues(sport)
    dispatch({
      type: GET_LEAGUES_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_LEAGUES_FAILURE,
      error
    });
  }
};

export const getLiveScores = (sport, league, fixture_id) => async dispatch => {
  dispatch({
    type: RESET_LIVE_GAME_SCORES_STATE
  });
  dispatch({
    type: GET_LIVE_GAME_SCORES_REQUEST
  });
  try {

    const response = await sportsService.getLiveScores(sport, league, fixture_id)
    dispatch({
      type: GET_LIVE_GAME_SCORES_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_LIVE_GAME_SCORES_FAILURE,
      error
    });
  }
};

export const getLiveOdds = (sport, league, game_id) => async dispatch => {
  dispatch({
    type: GET_LIVE_GAME_SCORES_REQUEST
  });
  try {
    const response = await sportsService.getLiveOdds(sport, league, game_id)
    dispatch({
      type: GET_LIVE_GAME_SCORES_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_LIVE_GAME_SCORES_FAILURE,
      error
    });
  }
};

export const getPlayerOdds = (sport, league, playerId) => async dispatch => {
  dispatch({
    type: GET_PLAYER_ODDS_REQUEST
  });
  try {
    const response = await sportsService.getPlayerOdds(sport, league, playerId);
    dispatch({
      type: GET_PLAYER_ODDS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_PLAYER_ODDS_FAILURE,
      error
    });
  }
};

export const getUpcomingMatchups = (sport, league, daysFromNow) => async dispatch => {
  dispatch({
    type: GET_UPCOMING_MATCHUPS_REQUEST
  });
  try {
    const response = await sportsService.getUpcomingMatchups(sport, league, daysFromNow);
    // console.log(`Dispatching upcoming matchups for league: ${league}`, response);
    dispatch({
      type: GET_UPCOMING_MATCHUPS_SUCCESS,
      payload: response,
      category: league,
    });
    return response;
  } catch (error) {
    console.error(`Error dispatching upcoming matchups for league: ${league}`, error);
    dispatch({
      type: GET_UPCOMING_MATCHUPS_FAILURE,
      error
    });
  }
};

export const getRandomUpcomingMatchups = () => async dispatch => {
  dispatch({
    type: GET_RANDOM_UPCOMING_MATCHUPS_REQUEST
  });
  try {
    const response = await sportsService.getRandomUpcomingMatchups();
    dispatch({
      type: GET_RANDOM_UPCOMING_MATCHUPS_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_RANDOM_UPCOMING_MATCHUPS_FAILURE,
      error
    });
  }
};

export const searchSports = topic => async dispatch => {
  dispatch({
    type: SEARCH_SPORTS_REQUEST
  });
  try {
    const response = await sportsService.searchSports(topic);
    dispatch({
      type: SEARCH_SPORTS_SUCCESS,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: SEARCH_SPORTS_FAILURE,
      error
    });
  }
};

// Fetch odds from multiple sportsbooks
export const fetchOddsForGame = (gameId, sportsbooks) => async dispatch => {
  dispatch({
    type: GET_ODDS_REQUEST
  });
  try {
    const response = await sportsService.getOddsForGame(gameId, sportsbooks);
    dispatch({
      type: GET_ODDS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_ODDS_FAILURE,
      error
    });
  }
};


export const fetchOddsForLeague = (league, date, oddsType, sportsbooks) => async dispatch => {
  dispatch({
    type: GET_LEAGUE_ODDS_REQUEST
  });
  try {
    const response = await sportsService.getLeagueOdds(league, date, oddsType, sportsbooks);
    dispatch({
      type: GET_LEAGUE_ODDS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_LEAGUE_ODDS_FAILURE,
      error
    });
  }
};


export const getUfcFighterDetails = (fighterId) => async dispatch => {
  dispatch({
    type: GET_UFC_FIGHTER_DETAILS_REQUEST
  });
  try {
    const params = {
      id: fighterId
    };
    const response = await sportsService.fetchPlayersList(params);
    dispatch({
      type: GET_UFC_FIGHTER_DETAILS_SUCCESS,
      payload: response
    });
    return response;
  } catch (error) {
    dispatch({
      type: GET_UFC_FIGHTER_DETAILS_FAILURE,
      error
    });
  }
};